import { render, staticRenderFns } from "./StatsChartConversions.vue?vue&type=template&id=30967ac5"
import script from "./StatsChartConversions.vue?vue&type=script&lang=js"
export * from "./StatsChartConversions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30967ac5')) {
      api.createRecord('30967ac5', component.options)
    } else {
      api.reload('30967ac5', component.options)
    }
    module.hot.accept("./StatsChartConversions.vue?vue&type=template&id=30967ac5", function () {
      api.rerender('30967ac5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stats/components/StatsChartConversions.vue"
export default component.exports