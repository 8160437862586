var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.knowledge && _vm.knowledge.length > 0
    ? _c(
        "div",
        { staticClass: "moin-fresh" },
        [
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0 primary--text text--lighten-2" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary lighten-2", left: "" } },
                    [_vm._v("school")]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("welcome.knowledge.title")) + " "),
                ],
                1
              ),
              _vm._l(_vm.knowledge, function (item, index) {
                return _c("v-hover", {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-0",
                                attrs: {
                                  href: item.link,
                                  target: "_blank",
                                  flat: "",
                                  color: hover ? "grey lighten-4" : "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "mt-0 mt-sm-4 mb-2 mb-sm-n4 px-2 py-0",
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        class: {
                                          "pr-0 mr-0":
                                            _vm.$vuetify.breakpoint.mdAndUp,
                                        },
                                        attrs: { cols: "12", sm: "3", md: "2" },
                                      },
                                      [
                                        _c("v-img", {
                                          staticClass: "ma-0 rounded-lg ml-0",
                                          class: {
                                            "pr-0 mr-0":
                                              _vm.$vuetify.breakpoint.mdAndUp,
                                          },
                                          attrs: {
                                            height: "auto",
                                            "max-height": "160",
                                            width: "280",
                                            src: item.image,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "9",
                                          md: "10",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "pl-0 pl-sm-4 pt-0 primary--text",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          {
                                            staticClass:
                                              "pl-0 pl-sm-4 pb-0 pb-sm-2 text-subtitle-1 primary--text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }