<template>
  <div>
    <v-btn color="primary" depressed small @click="isDialogOpen = true">
      {{ $t('livechat.chats.closeChatButton') }}
    </v-btn>
    <HubDialog
      v-model="isDialogOpen"
      :title="'Diesen Chat wirklich schließen?'"
      :confirmText="'Ja, schließen'"
      @onPrimary="closeChat()"
      @onSecondary="isDialogOpen = false"
    >
      <p>
        {{ $t('livechat.chats.closeChatDialogBody') }}
      </p>
    </HubDialog>
  </div>
</template>
<script>
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  name: 'LivechatCloseChat',
  components: {
    HubDialog,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDialogOpen: false,
    };
  },
  methods: {
    closeChat() {
      this.$store.dispatch('livechat/closeChat', this.chat);
    },
  },
};
</script>
