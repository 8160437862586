var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("integrations.shopware.title"))),
          ]),
          _c("p", { staticClass: "px-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("integrations.shopware.description")) + " "
            ),
          ]),
          _c(
            "v-btn-toggle",
            {
              staticClass: "pb-4 mx-4",
              attrs: {
                mandatory: "",
                borderless: "",
                color: "primary lighten-2",
              },
              model: {
                value: _vm.basicAuth,
                callback: function ($$v) {
                  _vm.basicAuth = $$v
                },
                expression: "basicAuth",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "120px" },
                  attrs: { value: false },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("integrations.shopware.integration.label"))
                    ),
                  ]),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "120px" },
                  attrs: { value: true },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("integrations.shopware.user.label"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mx-4 d-flex gap-4" },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t(
                    `integrations.shopware.${
                      _vm.basicAuth ? "user" : "integration"
                    }.identifier`
                  ),
                  placeholder: _vm.$t(
                    `integrations.shopware.${
                      _vm.basicAuth ? "user" : "integration"
                    }.placeholder`
                  ),
                  outlined: "",
                },
                model: {
                  value: _vm.identifier,
                  callback: function ($$v) {
                    _vm.identifier = $$v
                  },
                  expression: "identifier",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t(
                    `integrations.shopware.${
                      _vm.basicAuth ? "user" : "integration"
                    }.secret`
                  ),
                  type: "password",
                  outlined: "",
                },
                model: {
                  value: _vm.secret,
                  callback: function ($$v) {
                    _vm.secret = $$v
                  },
                  expression: "secret",
                },
              }),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "px-4",
            attrs: {
              label: _vm.$t("integrations.shopware.url.label"),
              rules: _vm.urlRules,
              placeholder: _vm.$t("integrations.shopware.url.placeholder"),
              outlined: "",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-4",
                  attrs: {
                    color: "success",
                    disabled:
                      !_vm.url || !_vm.identifier || !_vm.secret || _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.oauth()
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("common.connect")) + " "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-link-variant"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToast,
            callback: function ($$v) {
              _vm.savingToast = $$v
            },
            expression: "savingToast",
          },
        },
        [
          _c("v-icon", { attrs: { type: "success", left: "" } }, [
            _vm._v("check"),
          ]),
          _vm._v(
            " " + _vm._s(_vm.$t("integrations.shopware.alerts.success")) + " "
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToastError,
            callback: function ($$v) {
              _vm.savingToastError = $$v
            },
            expression: "savingToastError",
          },
        },
        [
          _c("v-icon", { attrs: { color: "red", type: "error", left: "" } }, [
            _vm._v("mdi-alert-circle"),
          ]),
          _vm._v(" " + _vm._s(_vm.message) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }