var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    [
      _vm.contact
        ? _c(
            "v-list-item",
            [
              _c(
                "v-list-item-avatar",
                { staticClass: "mr-4", attrs: { size: _vm.avatarSize } },
                [_c("v-img", { attrs: { src: _vm.contact.profileImage } })],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _vm.title
                    ? _c("v-list-item-title", {
                        staticClass:
                          "primary--text text--lighten-2 font-weight-bold mb-2",
                        domProps: { innerHTML: _vm._s(_vm.title) },
                      })
                    : _vm._e(),
                  _c("v-list-item-title", { staticClass: "primary--text" }, [
                    _vm._v(_vm._s(_vm.contact.name)),
                  ]),
                  _vm.large
                    ? _c(
                        "v-list-item-title",
                        { staticClass: "primary--text" },
                        [
                          _c(
                            "a",
                            { attrs: { href: "mailto:" + _vm.contact.eMail } },
                            [_vm._v(_vm._s(_vm.contact.eMail))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }