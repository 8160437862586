import { render, staticRenderFns } from "./IntentsRelevanceBar.vue?vue&type=template&id=082c01b9"
import script from "./IntentsRelevanceBar.vue?vue&type=script&lang=js"
export * from "./IntentsRelevanceBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('082c01b9')) {
      api.createRecord('082c01b9', component.options)
    } else {
      api.reload('082c01b9', component.options)
    }
    module.hot.accept("./IntentsRelevanceBar.vue?vue&type=template&id=082c01b9", function () {
      api.rerender('082c01b9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/intents/IntentsRelevanceBar.vue"
export default component.exports