<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen" fixedHeight>
      <template v-slot:navbar>
        <HubContentNavbar
          :title="$t('intents.hubListTitle')"
          :isLoading="isLoading"
        >
          <template v-slot:toolbar>
            <v-btn
              v-if="isNewIntentBtnShown"
              class="d-relative"
              :color="
                toolbarBtnInEdit ? 'secondary darken-3' : 'primary lighten-3'
              "
              text
              @click="createIntent"
            >
              <span>{{ $t('intents.hubListNewIntent') }}</span>
              <v-icon class="ml-2" right>mdi-plus-circle</v-icon>
              <v-icon
                v-if="toolbarBtnInEdit"
                size="12"
                color="secondary darken-3"
                class="ml-2"
                style="position: absolute; top: -6px; right: -14px"
              >
                edit
              </v-icon>
            </v-btn>
            <CreateIntent v-if="isAdmin" />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          v-if="isNewIntentOpen"
          :isOpen="isSidebarOpen"
          :title="isNewIntentOpen ? $t('intents.hubListNewIntent') : ''"
          :icon="isNewIntentOpen ? 'note_add' : 'info'"
          isProminent
          @onClose="closeNewIntent"
        >
          <div class="pa-4" v-if="isNewIntentOpen">
            <IntentNew />
          </div>
        </HubContentSidebarSimple>
        <HubContentSidebarSimple
          v-else
          :isOpen="isSidebarOpen"
          :title="''"
          :icon="'info'"
          @onClose="closeSidebar"
          :isProminent="isNewIntentOpen"
        >
          <div class="pa-4">
            <IntentItemInfo
              :intent="selectedIntent"
              :key="selectedIntent.intent"
            />
          </div>
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <HubFrame>
          <template v-slot:content>
            <IntentsList
              ref="intentsList"
              id="scroll-container"
              @onSelected="changeSelectedIntent"
              :isSidebarOpen="isSidebarOpen"
            />
            <SnackbarStack :scopes="['intents']" />
          </template>
        </HubFrame>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import HubFrame from '@/components/hub/HubFrame.vue';
import IntentsList from '@/components/intents/IntentsList.vue';
import IntentItemInfo from '@/components/intents/IntentsItemInfo.vue';
import IntentNew from '@/components/intentNew/IntentNew.vue';
import CreateIntent from '@/components/tools/CreateIntent.vue';
import SnackbarStack from '@/components/tools/SnackbarStack.vue';

export default {
  name: 'Intents',
  components: {
    SnackbarStack,
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    HubFrame,
    IntentsList,
    IntentItemInfo,
    IntentNew,
    CreateIntent,
  },
  data() {
    return {
      isNewIntentOpen: false,
      isLoading: false,
      isSidebarOpen: false,
      selectedIntent: {
        displayName: '',
      },
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAdmin',
      'isOwner',
      'isNewIntent',
      'isSimpleEdit',
    ]),
    ...mapGetters('intents', ['currentIntent']),
    ...mapGetters('bots', ['currentBotId']),
    isDemoLicence: function () {
      const currLicence = this.$store.getters['bots/currentLicence'];
      return currLicence?.package === 'demo';
    },
    isNewIntentBtnShown: function () {
      if (this.isAdmin) return true;
      return this.isSimpleEdit || this.isOwner;
    },
    toolbarBtnInEdit: function () {
      const intentNewStatus = this.$store.getters['intentNew/status'];
      return intentNewStatus !== 'initial';
    },
  },
  methods: {
    changeSelectedIntent(intent) {
      if (this.isNewIntentOpen) {
        this.closeNewIntent();
      }
      this.selectedIntent = intent;
      if (!this.isSidebarOpen && intent) {
        this.isSidebarOpen = true;
        this.scrollToItem(intent.intent);
      }
    },
    createIntent() {
      // opens prominent new intent sidebar
      this.$store.commit('intentNew/setIntentNewOpen', true);
      this.isNewIntentOpen = true;
      this.isSidebarOpen = true;
    },
    closeNewIntent() {
      this.isSidebarOpen = false;
      setTimeout(() => {
        this.isNewIntentOpen = false;
        this.scrollToItem(this.currentIntent.intent);
      }, 300);
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      setTimeout(() => {
        this.scrollToItem(this.selectedIntent.intent);
      }, 300);
    },
    async scrollToItem(intentId) {
      if (!intentId) return;
      this.$refs.intentsList.scrollToItem(intentId);
    },
  },
  created() {
    this.$store.dispatch('topicAssistant/resumeProcessing', {
      botId: this.currentBotId,
    });
  },
};
</script>
