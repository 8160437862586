var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          staticClass: "mb-4",
          attrs: {
            notExpandable: "",
            isLoading: _vm.isLoading,
            icon: "manage_search",
            title: _vm.$t("intentNew.intentInitial.titleFindIntent"),
          },
        },
        [
          _c("InfoBox", {
            attrs: {
              text: _vm.$t("intentNew.intentInitial.infoIntentExample"),
            },
          }),
          _c(
            "div",
            { staticClass: "my-7" },
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.searchRequest,
                  outlined: "",
                  "persistent-placeholder": "",
                  color: "secondary darken-2",
                  "hide-details": "",
                  autofocus: "",
                  clearable: "",
                  readonly: _vm.isLoading,
                  label: _vm.$t(
                    "intentNew.intentInitial.inputIntentExample.label"
                  ),
                  placeholder: _vm.$t(
                    "intentNew.intentInitial.inputIntentExample.placeholder"
                  ),
                  "prepend-inner-icon": "search",
                },
                on: {
                  input: _vm.updateSearchInput,
                  "click:clear": _vm.clearSearchResults,
                },
              }),
            ],
            1
          ),
          _vm.searchResults
            ? [
                _vm.searchResults.own && _vm.searchResults.own.length
                  ? _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        !_vm.isLoading
                          ? _c(
                              "h3",
                              {
                                staticClass:
                                  "text-h6 secondary--text text--darken-3",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "intentNew.intentInitial.titleFoundInIntents"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isLoading
                          ? _c("v-skeleton-loader", {
                              staticClass: "mb-8",
                              attrs: {
                                type: "list-item-avatar-two-line, list-item-avatar-two-line",
                              },
                            })
                          : _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item-group",
                                  _vm._l(
                                    _vm.searchResults.own,
                                    function (item) {
                                      return _c(
                                        "div",
                                        { key: `own_${item.label}` },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "px-2 pt-2 pb-1",
                                              attrs: {
                                                inactive: "",
                                                ripple: false,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.intentIcon(
                                                              _vm.getIntentFromLibraryByName(
                                                                item.label
                                                              )
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "py-0" },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.displayName(
                                                            item.label,
                                                            _vm.$vuetify.lang
                                                              .current
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _vm.getIntentFromLibraryByName(
                                                    item.label
                                                  ).description
                                                    ? _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          staticClass:
                                                            "pb-2 mt-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.description(
                                                                  item.label,
                                                                  _vm.$vuetify
                                                                    .lang
                                                                    .current
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchResults.all &&
                _vm.searchResults.all.length > 0 &&
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "text-h6 secondary--text text--darken-3",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "intentNew.intentInitial.titleFoundInTemplates"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item-group",
                              _vm._l(_vm.searchResults.all, function (item) {
                                return _c(
                                  "div",
                                  { key: `standard_${item.label}` },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "px-2 pt-2 pb-1",
                                        on: {
                                          click: function ($event) {
                                            _vm.newIntentWithTemplate(
                                              _vm.getIntentFromLibraryByName(
                                                item.label
                                              ),
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "primary lighten-3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.intentIcon(
                                                        _vm.getIntentFromLibraryByName(
                                                          item.label
                                                        )
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "py-0" },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getIntentFromLibraryByName(
                                                      item.label
                                                    ).suggested
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.displayName(
                                                      item.label,
                                                      _vm.$vuetify.lang.current
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _vm.getIntentFromLibraryByName(
                                              item.label
                                            ).description
                                              ? _c(
                                                  "v-list-item-subtitle",
                                                  { staticClass: "pb-2 mt-1" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.description(
                                                            item.label,
                                                            _vm.$vuetify.lang
                                                              .current
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "primary lighten-3",
                                                },
                                              },
                                              [_vm._v("navigate_next")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchResults?.newTopic && !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "text-h6 secondary--text text--darken-3 mb-4",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "intentNew.intentInitial.titleNewWithoutTemplate"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("InfoBox", {
                          attrs: {
                            text: _vm.$t(
                              "intentNew.intentInitial.infoNewWithoutTemplate"
                            ),
                          },
                        }),
                        _c(
                          "v-list",
                          { staticClass: "mt-4 pt-0" },
                          [
                            _c(
                              "v-list-item-group",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "px-2 py-1",
                                    on: { click: _vm.newIntentWithoutTemplate },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary lighten-3",
                                            },
                                          },
                                          [_vm._v(" create_new_folder ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "py-0" },
                                      [
                                        false
                                          ? _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "primary--text text--lighten-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "intentNew.intentInitial.itemNewWithoutTemplate"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "primary--text text--lighten-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.searchResults.newTopic
                                                    .title ||
                                                    _vm.$t(
                                                      "intentNew.intentInitial.titleNewWithoutTemplate"
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.searchResults.newTopic.description
                                          ? _c(
                                              "v-list-item-subtitle",
                                              { staticClass: "pb-2 mt-1" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.searchResults.newTopic
                                                        .description
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "primary lighten-3",
                                            },
                                          },
                                          [_vm._v("navigate_next")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "HubBox",
        {
          staticClass: "mb-4",
          attrs: {
            title: _vm.$t("intentNew.intentInitial.titleAllTemplates"),
            titleSub: `${_vm.existingIntentAmount}${" "}${_vm.$t(
              "intentNew.intentInitial.titleExistingTemplates"
            )}`,
            expandedInitial: "",
          },
        },
        [
          _c(
            "v-chip-group",
            {
              attrs: { column: "" },
              model: {
                value: _vm.activeIntentFilter,
                callback: function ($$v) {
                  _vm.activeIntentFilter = $$v
                },
                expression: "activeIntentFilter",
              },
            },
            _vm._l(_vm.intentFilterChips, function (item, index) {
              return _c(
                "v-chip",
                {
                  key: index,
                  class: { "white--text primary": item.active },
                  attrs: {
                    disabled: !item.amount,
                    value: index,
                    color: item.active ? "primary lighten-3" : "grey lighten-1",
                    "text-color": item.active ? "white" : "grey",
                    outlined: !item.active,
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: "",
                        color: item.active
                          ? "white"
                          : item.iconColor || "primary lighten-3",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(item.label)) +
                      " " +
                      _vm._s(item.amount) +
                      " "
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                _vm._l(_vm.getLibraryIntents, function (intent) {
                  return _c(
                    "div",
                    { key: intent._id },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "px-2",
                          on: {
                            click: function ($event) {
                              return _vm.newIntentWithTemplate(intent)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color: "primary lighten-3",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.intentIcon(intent)) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0" },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.displayName(
                                        intent.intent,
                                        _vm.$vuetify.lang.current
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "primary lighten-3" } },
                                [_vm._v("navigate_next")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }