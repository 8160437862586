var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hub-frame" }, [
    _c("div", { staticClass: "hub-frame-wrapper" }, [
      _c(
        "div",
        { staticClass: "hub-frame-content white relative" },
        [
          _vm.isLoading
            ? _c("v-progress-linear", {
                staticClass: "hub-frame-progress",
                attrs: {
                  indeterminate: "",
                  absolute: "",
                  color: "secondary darken-2",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "hub-frame-header" }, [_vm._t("header")], 2),
          _c("div", { staticClass: "pa-4" }, [_vm._t("content")], 2),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }