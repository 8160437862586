var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh" },
    [
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "8" } },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0 primary--text text--lighten-2" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary lighten-2", left: "" } },
                    [_vm._v("speed")]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("welcome.stats.title")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-select", {
                staticClass: "mt-n4",
                attrs: {
                  items: _vm.datePeriods,
                  value: _vm.statsDatePeriod,
                  "item-value": "value",
                  "item-text": "label",
                },
                on: { change: _vm.onChangePeriod },
                scopedSlots: _vm._u([
                  {
                    key: "prepend-inner",
                    fn: function () {
                      return [
                        _c(
                          "v-icon",
                          { attrs: { color: "primary lighten-4" } },
                          [_vm._v("mdi-calendar")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.currentBotStage === _vm.stageTypes.ONBOARDING
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.intentsCreated"),
                      newValue: 10,
                      oldValue: 8,
                      loading: _vm.loading,
                      isPercent: true,
                      icon: "source",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.intentsEdited"),
                      newValue: 89,
                      oldValue: 87,
                      loading: _vm.loading,
                      icon: "edit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.conversationsTested"),
                      newValue: 100,
                      oldValue: 80,
                      icon: "forum",
                      loading: _vm.loading,
                      isPercent: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentBotStage === _vm.stageTypes.LIVE
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.conversations"),
                      icon: "chat",
                      newValue: _vm.StatsChartConversations.new,
                      oldValue: _vm.StatsChartConversations.old,
                      loading: _vm.StatsChartConversations.isLoading,
                      noData: _vm.StatsChartConversations.isFailed,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.automations"),
                      icon: "memory",
                      newValue: _vm.StatsChartAutomation.new,
                      oldValue: _vm.StatsChartAutomation.old,
                      loading: _vm.StatsChartAutomation.isLoading,
                      isPercent: true,
                      noData: _vm.StatsChartAutomation.isFailed,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.feedbackPositive"),
                      icon: "thumb_up",
                      newValue: _vm.StatsChartFeedbackPos.new,
                      oldValue: _vm.StatsChartFeedbackPos.old,
                      loading: _vm.StatsChartFeedbackPos.isLoading,
                      noData: _vm.StatsChartFeedbackPos.isFailed,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c("IconNumberTeaser", {
                    attrs: {
                      title: _vm.$t("welcome.stats.feedbackNegative"),
                      icon: "thumb_down",
                      newValue: _vm.StatsChartFeedbackNeg.new,
                      oldValue: _vm.StatsChartFeedbackNeg.old,
                      loading: _vm.StatsChartFeedbackNeg.isLoading,
                      lessIsBetter: true,
                      noData: _vm.StatsChartFeedbackNeg.isFailed,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }