import { render, staticRenderFns } from "./StatsChartTakeovers.vue?vue&type=template&id=50bc8120"
import script from "./StatsChartTakeovers.vue?vue&type=script&lang=js"
export * from "./StatsChartTakeovers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50bc8120')) {
      api.createRecord('50bc8120', component.options)
    } else {
      api.reload('50bc8120', component.options)
    }
    module.hot.accept("./StatsChartTakeovers.vue?vue&type=template&id=50bc8120", function () {
      api.rerender('50bc8120', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stats/components/StatsChartTakeovers.vue"
export default component.exports