import { render, staticRenderFns } from "./StatsChart.vue?vue&type=template&id=df1d9030&scoped=true"
import script from "./StatsChart.vue?vue&type=script&lang=js"
export * from "./StatsChart.vue?vue&type=script&lang=js"
import style0 from "./StatsChart.vue?vue&type=style&index=0&id=df1d9030&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df1d9030",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df1d9030')) {
      api.createRecord('df1d9030', component.options)
    } else {
      api.reload('df1d9030', component.options)
    }
    module.hot.accept("./StatsChart.vue?vue&type=template&id=df1d9030&scoped=true", function () {
      api.rerender('df1d9030', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stats/components/StatsChart.vue"
export default component.exports