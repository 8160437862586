var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        scopedSlots: _vm._u(
          [
            {
              key: "navbar",
              fn: function () {
                return [
                  _c("HubContentNavbar", {
                    attrs: { title: "Dashboard", isLoading: _vm.isLoading },
                  }),
                ]
              },
              proxy: true,
            },
            _vm.currentBotId
              ? {
                  key: "content",
                  fn: function () {
                    return [
                      _c("HubFrameSimple", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [_c("WelcomeHeaderBar")]
                              },
                              proxy: true,
                            },
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("WelcomeAvatar", {
                                    staticClass: "pt-8 pb-14",
                                  }),
                                  _c("WelcomeSupport", {
                                    staticClass: "pb-14",
                                  }),
                                  _vm.currentBotStage === _vm.stageTypes.LIVE
                                    ? _c("WelcomeStats", {
                                        staticClass: "pb-14",
                                      })
                                    : _vm._e(),
                                  _c("WelcomeKnowledge", {
                                    staticClass: "pb-5",
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2880704695
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }