import { render, staticRenderFns } from "./WelcomeHeaderBar.vue?vue&type=template&id=79f6e3c1"
import script from "./WelcomeHeaderBar.vue?vue&type=script&lang=js"
export * from "./WelcomeHeaderBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79f6e3c1')) {
      api.createRecord('79f6e3c1', component.options)
    } else {
      api.reload('79f6e3c1', component.options)
    }
    module.hot.accept("./WelcomeHeaderBar.vue?vue&type=template&id=79f6e3c1", function () {
      api.rerender('79f6e3c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/welcome/WelcomeHeaderBar.vue"
export default component.exports