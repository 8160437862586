var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { color: "primary", depressed: "", small: "" },
          on: {
            click: function ($event) {
              _vm.isDialogOpen = true
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("livechat.chats.closeChatButton")) + " ")]
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            title: "Diesen Chat wirklich schließen?",
            confirmText: "Ja, schließen",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.closeChat()
            },
            onSecondary: function ($event) {
              _vm.isDialogOpen = false
            },
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("livechat.chats.closeChatDialogBody")) + " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }