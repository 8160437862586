var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex items-center",
                  staticStyle: { "min-height": "70vh" },
                },
                [
                  _c("HubBoxSimple", {
                    staticClass: "mx-auto pa-6",
                    staticStyle: { width: "100%", "max-width": "700px" },
                    attrs: { isLoading: _vm.isLoading },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", [
                              _c("h2", { staticClass: "text-h5 mb-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settingsLivechat.featurePopup.notActive"
                                    )
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "text-body-1 mb-8" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settingsLivechat.featurePopup.introDescription"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "h3",
                                { staticClass: "text-h6 secondary--text mb-6" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.featurePopup.highlightTitle"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 text-body-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "secondary" },
                                    },
                                    [_vm._v(" mdi-check-decagram ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.featurePopup.highlightPoints.startChatFromWidget"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 text-body-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "secondary" },
                                    },
                                    [_vm._v(" mdi-check-decagram ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.featurePopup.highlightPoints.answerFromHub"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 text-body-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "secondary" },
                                    },
                                    [_vm._v(" mdi-check-decagram ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.featurePopup.highlightPoints.groupsAndAgents"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 text-body-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "secondary" },
                                    },
                                    [_vm._v(" mdi-check-decagram ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.featurePopup.highlightPoints.noThirdParty"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-end" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        disabled: !_vm.isAdmin && !_vm.isOwner,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createLivechat()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "settingsLivechat.featurePopup.ctaLabel"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(
                                          "mdi-package-variant-closed-plus"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }