<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />

    <Appbar />

    <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('botSettings.title')" />
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="closeSidebar()"
          icon="info"
        >
          <div class="pa-4">
            <HubBoxSimple
              v-if="selectedSubpage"
              :title="selectedSubpage.title"
              :icon="selectedSubpage.icon"
            >
              <template v-slot:content>
                <p class="text-body-2">
                  {{ selectedSubpage.descriptionLong }}
                </p>
                <v-btn
                  class="mt-2"
                  outlined
                  color="primary lighten-2"
                  :to="`settings/${selectedSubpage.slug}`"
                >
                  {{ $t('common.edit') }}
                  <v-icon right>edit</v-icon>
                </v-btn>
              </template>
            </HubBoxSimple>
          </div>
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-list-item-group
                class="white"
                :value="selectedSubpage"
                color="primary"
                @change="selectSubpage"
                rounded
              >
                <template v-for="(subPage, index) in subPages">
                  <v-list-item
                    :value="subPage"
                    :key="subPage.slug"
                    :to="`/settings/${subPage.slug}`"
                  >
                    <v-list-item-content>
                      <div class="d-sm-flex">
                        <div class="flex-grow-auto d-flex align-center">
                          <v-card
                            outlined
                            class="flex-shrink-0 d-flex a-lign-center justify-center"
                            style="height: 70px; width: 116px"
                          >
                            <v-icon color="primary lighten-3">
                              {{ subPage.icon }}
                            </v-icon>
                          </v-card>
                        </div>
                        <div class="flex-grow-auto d-flex align-center pa-4">
                          <div class="pt-2">
                            <h3
                              class="text-h5 text-left"
                              :class="[subPage.adminOnly && 'admin--text']"
                            >
                              {{ subPage.title }}
                            </h3>
                            <p
                              class="primary--text text--lighten-3 text-body-2 text-left"
                            >
                              <span v-html="subPage.description"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index < subPages.length - 1" :key="index" />
                </template>
              </v-list-item-group>
              <v-card
                outlined
                v-if="isAdmin && !quota"
                class="mt-4 pa-10 text-body-1 grey--text text--darken-2 text-center"
              >
                Für diesen Bot sind unter
                <a href="/tools">tools</a>
                noch keine Quota gesetzt.
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'Settings',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    HubBoxSimple,
  },
  data() {
    return {
      isSidebarOpen: false,
      selectedSubpage: null,
    };
  },
  created () {
    this.trackIt();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOwner', 'isLivechat']),
    ...mapGetters('bots', [
      'currentBotId',
      'getBotSettings',
      'hasKeywordMatchFeature',
    ]),
    subPages() {
      const pageList = [
        {
          title: this.$t('botSettings.roles.title'),
          icon: 'mdi-account-cog',
          slug: 'roles',
          description: this.$t('botSettings.roles.description'),
          descriptionLong: this.$t('botSettings.roles.description'),
        },
        {
          title: this.$t('botSettings.privacy.title'),
          icon: 'mdi-shield-account',
          slug: 'privacy',
          description: this.$t('botSettings.privacy.description'),
          descriptionLong: this.$t('botSettings.privacy.description'),
        },
        {
          title: this.$t('channelManagement.title'),
          icon: 'mdi-format-list-bulleted-type',
          slug: 'channels',
          description: this.$t('channelManagement.description'),
          descriptionLong: this.$t('channelManagement.description'),
        },
      ];

      if (this.isAdmin) {
        pageList.push({
          title: this.$t('botSettings.regexp.title'),
          icon: 'mdi-regex',
          slug: 'regexp',
          description: this.$t('botSettings.regexp.description'),
          descriptionLong: this.$t('botSettings.regexp.description'),
          adminOnly: true,
        });
      }

      if (this.isAdmin) {
        pageList.push({
          title: this.$t('botSettings.fileUpload.title'),
          icon: 'mdi-file-cog-outline',
          slug: 'files',
          description: this.$t('botSettings.fileUpload.description'),
          descriptionLong: this.$t('botSettings.fileUpload.description'),
          adminOnly: true,
        });

        pageList.push({
          title: this.$t('botSettings.toneOfVoice.title'),
          icon: 'mdi-robot',
          slug: 'ai/tone-of-voice',
          description: this.$t('botSettings.toneOfVoice.description'),
          descriptionLong: this.$t('botSettings.toneOfVoice.description'),
          adminOnly: true,
        });
      }

      if (this.isAdmin && this.quota) {
        pageList.push({
          title: this.$t('botSettings.contingents.title'),
          icon: 'mdi-shopping',
          slug: 'contingents',
          description: this.$t('botSettings.contingents.description'),
          descriptionLong: this.$t('botSettings.contingents.description'),
          adminOnly: true,
        });
      }

      if ((this.isAdmin || this.isOwner) && this.isLivechat ) {
        pageList.push({
          title: 'Livechat',
          icon: 'mdi-face-agent',
          slug: 'livechat',
          description: 'Verwalte den Livechat für deinen Bot.',
          descriptionLong:
            'Verwalte den Livechat für deinen Bot. Agenten können über den Livechat mit deinen Nutzern chatten.',
        });
      }

      return pageList;
    },
    quota() {
      return this.getBotSettings(this.currentBotId)?.quota || null;
    },
  },
  methods: {
    selectSubpage(value) {
      if (!value) this.closeSidebar();
      this.selectedSubpage = value;
      if (this.selectedSubpage) this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.selectedSubpage = null;
      this.isSidebarOpen = false;
    },
    trackIt(type = 'view') {
      TrackingService.trackSettings({
        botId: this.currentBotId,
        type: type,
        source: 'Settings.vue',
      });
    },
  },
};
</script>
