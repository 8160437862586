var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "relative mt-n4 mb-4 mx-n4",
          staticStyle: {
            height: "16px",
            overflow: "hidden",
            "border-radius": "4px 4px 0 0",
          },
        },
        [
          _vm.isLoading
            ? _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  absolute: "",
                  color: "secondary darken-2",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "my-2" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-icon",
                { attrs: { color: "primary lighten-3", size: "16" } },
                [_vm._v("mdi-filter")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-body-2 primary--text text--lighten-2 pl-1",
                },
                [_vm._v(" " + _vm._s(_vm.$t("intents.filterTypeLabel")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { height: "48px" } },
            [
              !_vm.isLoading
                ? _c("ChipFilter", {
                    attrs: {
                      options: _vm.filterTypeItems,
                      disableNoAmount: "",
                    },
                    on: { onChange: _vm.changeFilterType },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "my-2" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-icon",
                { attrs: { color: "primary lighten-3", size: "16" } },
                [_vm._v("mdi-filter")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-body-2 primary--text text--lighten-2 pl-1",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("intents.filterStatusLabel")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { height: "48px" } },
            [
              !_vm.isLoading
                ? _c("ChipFilter", {
                    attrs: { options: _vm.filterStatusItems },
                    on: { onChange: _vm.changeFilterStatus },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.canUseAISearch
          ? _c("div", { staticClass: "my-2 hidden-md-and-up" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary lighten-3", size: "16" } },
                    [_vm._v("mdi-filter")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 primary--text text--lighten-2 pl-1",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("intents.filterSearchLabel")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "py-1 font-weight-regular",
                  staticStyle: { width: "320px", height: "48px" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.$t("intents.filterSearchPlaceholder"),
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      "prepend-inner-icon": _vm.usingAISearchResults
                        ? "close"
                        : "sort",
                      "hide-details": "",
                      readonly: _vm.isLoading,
                    },
                    on: {
                      keydown: _vm.handleAISearchKeyDown,
                      "click:prepend-inner": _vm.handleSearchbarIconClick,
                    },
                    model: {
                      value: _vm.aiSearch,
                      callback: function ($$v) {
                        _vm.aiSearch = $$v
                      },
                      expression: "aiSearch",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      !_vm.isLoading && _vm.allIntents && _vm.tableOptions
        ? _c("v-data-table", {
            attrs: {
              items: _vm.allIntents,
              headers: _vm.tableHeaders,
              options: _vm.tableOptions,
              page: _vm.page,
              "item-class": (item) =>
                _vm.selectedIntent && item.intent === _vm.selectedIntent.intent
                  ? "item item-active"
                  : "item",
              "items-per-page": 20,
              "footer-props": {
                "items-per-page-options": [20, 50, -1],
              },
              "hide-default-footer":
                _vm.allIntents && _vm.allIntents.length < 20,
              "no-data-text": _vm.$t("intents.noDataFound"),
              "custom-sort": _vm.customSort,
            },
            on: {
              "click:row": _vm.selectIntent,
              "update:options": _vm.setTableOptions,
              "update:page": _vm.setCurrentPage,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `header.status`,
                  fn: function () {
                    return [
                      _vm.filterChannelItems &&
                      _vm.filterChannelItems.length > 1
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("v-select", {
                                staticClass: "d-inline-block text-body-2",
                                attrs: {
                                  outlined: "",
                                  label: _vm.$t("intents.channelDropdownLabel"),
                                  items: _vm.filterChannelItems,
                                  value: _vm.selectedFilterChannel,
                                  "item-text": "displayName",
                                  "item-value": "channelId",
                                  dense: "",
                                  "hide-details": "",
                                },
                                on: { change: _vm.changeFilterChannel },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `header.displayName`,
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "search-container items-center gap-2",
                          class: { "w-fit": !_vm.canUseAISearch },
                        },
                        [
                          _c("v-data-table-header", {
                            attrs: {
                              headers: [
                                {
                                  text: _vm.$t("intents.headers.name"),
                                  sortable: false,
                                },
                              ],
                            },
                          }),
                          _vm.canUseAISearch
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "inner-search-container justify-center grow hidden-sm-and-down",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "py-1 font-weight-regular",
                                      staticStyle: {
                                        width: "320px",
                                        height: "48px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "intents.filterSearchPlaceholder"
                                          ),
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          "prepend-inner-icon":
                                            _vm.usingAISearchResults
                                              ? "close"
                                              : "sort",
                                          "hide-details": "",
                                          readonly: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          "click:prepend-inner":
                                            _vm.handleSearchbarIconClick,
                                          keydown: _vm.handleAISearchKeyDown,
                                        },
                                        model: {
                                          value: _vm.aiSearch,
                                          callback: function ($$v) {
                                            _vm.aiSearch = $$v
                                          },
                                          expression: "aiSearch",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `item.type`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            size: "20",
                            color:
                              item.type && _vm.typeIcons[item.type].iconColor,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.intentIcon(item)) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: `item.displayName`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "py-2", attrs: { id: item.intent } },
                        [
                          item.type === _vm.typeTypes.SMALLTALK
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("intent.smalltalk.label")) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.type === _vm.typeTypes.AI_SUGGESTION
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("intent.aiSuggestion.label")
                                      ) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.type === _vm.typeTypes.AI_TEMPLATE
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("intent.aiTemplate.label")
                                      ) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("span", { staticClass: "text-body-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.displayName(
                                    item.intent,
                                    _vm.user.language
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          item.inReview ? _c("v-icon") : _vm._e(),
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "div",
                                { staticClass: "text-body-2 grey--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.description(
                                          item.intent,
                                          _vm.user.language
                                        ) &&
                                          _vm.description(
                                            item.intent,
                                            _vm.user.language
                                          ).length > 0
                                          ? _vm.description(
                                              item.intent,
                                              _vm.user.language
                                            )
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.relevanceFrequent`,
                  fn: function ({ item }) {
                    return [
                      item.type !== _vm.typeTypes.SMALLTALK &&
                      item.status !== _vm.statusTypes.IN_REVIEW
                        ? _c("IntentsRelevanceBar", { attrs: { intent: item } })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: `item.status`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          item.type !== _vm.typeTypes.AI_SUGGESTION
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  item.status
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              size: "20",
                                              color:
                                                _vm.statusIcons[item.status]
                                                  .iconColor,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.statusIcons[item.status]
                                                    .icon
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    `intentStatus.${item.status}.label`
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-body-2 primary--text",
                                          },
                                          [_vm._v("...")]
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _c("div"),
                          _c(
                            "div",
                            [
                              item.type !== _vm.typeTypes.AI_SUGGESTION
                                ? _c("BtnSquare", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "edit",
                                      "data-cy": "edit-intent-btn",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToEdit(item.intent)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              item.type === _vm.typeTypes.AI_SUGGESTION &&
                              _vm.isCreateBtnShown
                                ? _c("BtnSquare", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "mdi-plus-box",
                                      colorIcon: "secondary darken-2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCreateDialog(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("IntentsDialogSuggestion", {
        attrs: {
          isOpen: _vm.isSuggestionDialogOpen,
          intent: _vm.suggestedIntent,
        },
        on: {
          onClose: function ($event) {
            _vm.isSuggestionDialogOpen = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }