var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: "KI Vorschlag",
        icon: "auto_awesome",
        boxId: "'intents-status-suggested'",
        expandedInitial: "",
      },
      on: {
        onExpand: (isExpanded) => isExpanded && _vm.trackIt("hub-box-expanded"),
      },
    },
    [
      _c("div", { staticClass: "mb-4 text-body-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("editor.suggested.description")) + " "),
      ]),
      _vm.isAdmin
        ? _c("div", { staticClass: "text-body-1 mb-1" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("intents.answers.status.forwardLabel")) + " "
            ),
          ])
        : _vm._e(),
      _vm.isCreateBtnShown
        ? [
            _vm.isAdmin
              ? _c(
                  "div",
                  { staticClass: "text-body-1 font-weight-bold" },
                  [
                    _c("v-icon", { attrs: { small: "", left: "" } }, [
                      _vm._v("directions"),
                    ]),
                    _vm.status.length > 0 && _vm.status[0].forward
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getIntentDisplayName(
                                  _vm.status[0].forward,
                                  _vm.intents
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.status.length > 0 && !_vm.status[0].forward
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.conversational.unknown.title")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: { outlined: "", color: "primary lighten-3" },
                    on: {
                      click: function ($event) {
                        _vm.isSuggestionDialogOpen = true
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.accept")))]),
                    _c(
                      "v-icon",
                      { attrs: { right: "", color: "primary lighten-3" } },
                      [_vm._v("add_box")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("IntentsDialogSuggestion", {
        attrs: { isOpen: _vm.isSuggestionDialogOpen, intent: _vm.intent },
        on: {
          onClose: function ($event) {
            _vm.isSuggestionDialogOpen = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }