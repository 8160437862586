<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col class="d-flex items-center" style="min-height: 70vh">
          <HubBoxSimple
            class="mx-auto pa-6"
            style="width: 100%; max-width: 700px"
            :isLoading="isLoading"
          >
            <template v-slot:content>
              <div>
                <h2 class="text-h5 mb-2">{{ $t('settingsLivechat.featurePopup.notActive') }}</h2>
                <p class="text-body-1 mb-8">
                  {{ $t('settingsLivechat.featurePopup.introDescription') }}
                </p>
                <h3 class="text-h6 secondary--text mb-6">
                  {{ $t('settingsLivechat.featurePopup.highlightTitle') }}
                </h3>
                <div class="mb-2 text-body-2">
                  <v-icon color="secondary" class="mr-1">
                    mdi-check-decagram
                  </v-icon>
                  {{ $t('settingsLivechat.featurePopup.highlightPoints.startChatFromWidget') }}
                </div>
                <div class="mb-2 text-body-2">
                  <v-icon color="secondary" class="mr-1">
                    mdi-check-decagram
                  </v-icon>
                  {{ $t('settingsLivechat.featurePopup.highlightPoints.answerFromHub') }}
                </div>
                <div class="mb-2 text-body-2">
                  <v-icon color="secondary" class="mr-1">
                    mdi-check-decagram
                  </v-icon>
                  {{ $t('settingsLivechat.featurePopup.highlightPoints.groupsAndAgents') }}
                </div>
                <div class="mb-2 text-body-2">
                  <v-icon color="secondary" class="mr-1">
                    mdi-check-decagram
                  </v-icon>
                  {{ $t('settingsLivechat.featurePopup.highlightPoints.noThirdParty') }}
                </div>

                <div class="d-flex justify-end">
                  <v-btn
                    class="mt-4"
                    depressed
                    color="secondary"
                    @click="createLivechat()"
                    :disabled="!isAdmin && !isOwner"
                  >
                    {{ $t('settingsLivechat.featurePopup.ctaLabel') }}
                    <v-icon right>mdi-package-variant-closed-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import LivechatService from '@/services/livechat';

export default {
  name: 'SettingsLivechatInactive',
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'isOwner',]),
    ...mapGetters('bots', ['currentBot', 'getBotSettings']),
  },
  components: { HubBoxSimple },
  data() {
    return {
      isDialogRequestOpen: false,
      isLoading: false,
    };
  },
  methods: {
    async createLivechat() {
      try {
        this.isLoading = true;
        const organization = await this.$store.dispatch('livechat/createLivechat', {
          uniqueBotId: this.currentBot?.uniqueBotId,
        });

        const currentSettings = this.getBotSettings(this.currentBot?.uniqueBotId);
        if (!currentSettings.moin_livechat) {
          await LivechatService.addBotSettings({botId: this.currentBot.uniqueBotId, defaultGroup: organization.groups[0]});
          await this.$store.dispatch('bots/getBotById', this.currentBot.uniqueBotId);
        }

        await this.$store.dispatch('livechat/init', {
          botId: this.currentBot.uniqueBotId,
          routePath: this.$route.path,
        });

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
