var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chip-group",
    { attrs: { multiple: "", column: "" }, on: { change: _vm.selectOption } },
    _vm._l(_vm.options, function (item, index) {
      return _c(
        "v-tooltip",
        {
          key: index,
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-chip",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "pl-4",
                            class: [
                              item.isActive
                                ? "primary lighten-2 white--text"
                                : "white primary--text text-lighten-2",
                              !_vm.showText ? "icon-only" : "",
                            ],
                            style: {
                              border: `1px solid ${
                                !item.isActive
                                  ? "rgba(189, 189, 189, 1)"
                                  : "var(--v-primary-lighten3)"
                              } !important`,
                            },
                            attrs: {
                              disabled:
                                item.isDisabled ||
                                (_vm.disableNoAmount && !item.amount),
                            },
                          },
                          "v-chip",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "v-icon",
                          {
                            class: { "mr-2": _vm.showText },
                            attrs: {
                              small: "",
                              color: item.isActive
                                ? "white"
                                : item.iconColor
                                ? item.iconColor
                                : "primary lighten-3",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.icon) + " ")]
                        ),
                        _vm.showText
                          ? _c("span", [_vm._v(_vm._s(item.name))])
                          : _vm._e(),
                        item.amount
                          ? _c(
                              "span",
                              {
                                staticClass: "pl-2",
                                staticStyle: { opacity: "0.6" },
                              },
                              [_vm._v(_vm._s(item.amount))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          item.isActive
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("common.filter.off")) + " "),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.$t("common.filter.on")))]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }