var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "moin-fresh mb-6 text-center pa-2 text-subtitle-1",
      class: _vm.stageBarClasses,
    },
    [
      _vm._v(
        " " +
          _vm._s(_vm.$t(`welcome.topbar.${_vm.currentBotStage}.title`)) +
          " "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }